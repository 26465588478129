* {
  margin: 0;
  padding: 0;
}
.main-card {
  // background-image: url(../../../../../public/media/Dashboard/Rectangle1.png);
  background-position: center;
  padding: 40px !important;
  background-repeat: no-repeat;
  background-size: cover;
  // object-fit: cover;
}
.second-card .nav-link.active {
  background-color: #e0f3fe !important;
  color: #292929 !important;
  border-radius: 0;
}
.second-card .nav-link {
  color: #6d6d6d !important;
  border-radius: 0;
}
.nav-pills .nav-item {
  margin-right: 0 !important;
}
.nav-link:hover {
  color: #292929 !important;
}
.nav-link .vcard{
  color: #6d6d6d;
  border-radius: 0 !important;
  border-right: 1px solid #e7e7e7 !important;
}
.patterns
{
  border-right: 0px  !important;
}
.card-input {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.4) 23.23%,
    rgba(241, 242, 243, 0.4) 87.37%
  );
  backdrop-filter: blur(1px);
  border-radius: '2px';
}
.card-circle {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.4) 23.23%,
    rgba(241, 242, 243, 0.4) 87.37%
  );
  backdrop-filter: blur(1px);
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 12px !important;
  width: 40px !important;
  height: 25px !important;
  line-height: 25px !important;
  margin: auto !important;
  text-align: center !important;
  background-color: #6d6d6d;
  opacity: 0.5;
  border-radius: 50% !important;
  color: white !important;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0 !important;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 0px !important;
  left: 0px;
}

// dropzone start //

.image-drop-zone {
  // width: 70%;
  height: 150px;
  border: 2px dashed #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}

.image-drop-zone img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.accordion-body button {
  // margin: px;
}



input[type='file'] {
  display: none;
}

// dropzone end //

// media quary

@media only screen and (max-width: 478px) {
  .main-card {
    // background-image: url(../../../../../public/media/Dashboard/Rectangle1.png);
    width: 100%;
    height: 200px;
    background-position: center;
    padding: 10px !important;
    background-repeat: no-repeat;
  }
}
