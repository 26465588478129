//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base/layout';
@import 'layout/layout';

@font-face {
  font-family: 'Geist';
  src: url('../../helpers/Geist\ -\ Copy/Geist-Regular.otf');
}

body {
  font-family: "Geist";
}

.bg-black{
  background-color: black;
}
.bg-white{
  background-color: #fff;
}
.bg-dark1{
  background-color: #292929;
}
.bg-blue{
  background-color: #0069E3 !important;
}

.font-black{
  color: #000 !important;
}
.font-dark1{
  color: #292929;
}
.font-blue{
  color: #0069E3 !important;
}
.font-gray1{
  color: #767676 !important;
}
.font-gray2{
  color: #565656;
}
.bg-btn{
  background-color: #Eff0f1 !important;
}
.border-gray{
  border: 1px solid #858585 !important;
}

.icon-btn{
  background-color: #F8F9F9 !important;
}
.icon-btn:hover{
    background-color:#Eff0f1  !important;
}

.text-nowrap{
  text-wrap: nowrap !important;
}

.cur-alias {cursor: alias;}
.cur-all-scroll {cursor: all-scroll;}
.cur-auto {cursor: auto;}
.cur-cell {cursor: cell;}
.cur-context-menu {cursor: context-menu;}
.cur-col-resize {cursor: col-resize;}
.cur-copy {cursor: copy;}
.cur-crosshair {cursor: crosshair;}
.cur-default {cursor: default;}
.cur-e-resize {cursor: e-resize;}
.cur-ew-resize {cursor: ew-resize;}
.cur-grab {cursor: -webkit-grab; cursor: grab;}
.cur-grabbing {cursor: -webkit-grabbing; cursor: grabbing;}
.cur-help {cursor: help;}
.cur-move {cursor: move;}
.cur-n-resize {cursor: n-resize;}
.cur-ne-resize {cursor: ne-resize;}
.cur-nesw-resize {cursor: nesw-resize;}
.cur-ns-resize {cursor: ns-resize;}
.cur-nw-resize {cursor: nw-resize;}
.cur-nwse-resize {cursor: nwse-resize;}
.cur-no-drop {cursor: no-drop;}
.cur-none {cursor: none;}
.cur-not-allowed {cursor: not-allowed;}
.cur-pointer {cursor: pointer;}
.cur-progress {cursor: progress;}
.cur-row-resize {cursor: row-resize;}
.cur-s-resize {cursor: s-resize;}
.cur-se-resize {cursor: se-resize;}
.cur-sw-resize {cursor: sw-resize;}
.cur-text {cursor: text;}
.cur-w-resize {cursor: w-resize;}
.cur-wait {cursor: wait;}
.cur-zoom-in {cursor: zoom-in;}
.cur-zoom-out {cursor: zoom-out;}
