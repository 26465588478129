/* React Bootstrap ProgressBar Override */
.mindar-ui-overlay .progress {
  --bs-progress-bar-transition: width 1.1s ease-in-out !important
}

/* Animations and States */

.mindar-ui-overlay .opacity-zero {
  opacity: 0 !important;
}

.mindar-ui-overlay .opacity-one {
  opacity: 1 !important;
}

.mindar-ui-overlay .scale-zero {
  transform: scale(0) !important;
}

.mindar-ui-overlay .scale-one {
  transform: scale(1) !important;
}

.mindar-ui-overlay .fade {
  transition: opacity 0.5s ease-in-out 1s;
}

.mindar-ui-overlay .scale {
  transition: transform 1s ease-in-out 1s;
}

@keyframes forBestResultsAnimation {
  0%   { opacity: 0; }
  3%   { opacity: 1; }
  40%  { opacity: 1; }
  45%  { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes scanHandAnimation {
  0% {
    opacity: 1;
    transform: translateX(-300%);
    -webkit-transform: translateX(-300%);
  }
  18% {
    transform: translateX(-3%);
    -webkit-transform: translateX(-3%);
  }
  40% {
    opacity: 1;
    transform: translateX(-3%);
    -webkit-transform: translateX(-3%);
  }
  45% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scanVideoAnimation {
  0%   { opacity: 0; }
  18%  { opacity: 0; }
  22%  { opacity: 0.99; }
  40%  { opacity: 0.99; }
  45%  { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes scanMarkerAnimation {
  0%   { opacity: 0; }
  3%   { opacity: 1; }
  40%  { opacity: 1; }
  45%  { opacity: 0; }
  100% { opacity: 0; }
}

/* Regular CSS */

.hand-scan-container .for-best-results {
  z-index: 4;
  top: -20px;
  font-size: 15px;
}

.hand-scan-container .for-best-results-anim {
  animation: forBestResultsAnimation 14s infinite;
  -webkit-animation: forBestResultsAnimation 14s infinite;
}

.hand-scan-container .scan-hand {
  z-index: 4;
  top: 15px;
  height: 300px;
}

.hand-scan-container .scan-hand-anim {
  animation: scanHandAnimation 14s infinite;
  -webkit-animation: scanHandAnimation 14s infinite;
}

.hand-scan-container .scan-video {
  z-index: 3;
  top: 45px;
  height: 135px;
}

.hand-scan-container .scan-video-anim {
  animation: scanVideoAnimation 14s infinite;
  -webkit-animation: scanVideoAnimation 14s infinite;
}

.hand-scan-container .marker {
  z-index: 2;
  top: 45px;
  max-height: 135px;
  max-width: 300px;
}

.hand-scan-container .marker-anim {
  animation: scanMarkerAnimation 14s infinite;
  -webkit-animation: scanMarkerAnimation 14s infinite;
}

.hand-scan-container .hold-phone {
  z-index: 1;
  top: 40px;
  height: 300px;
}

.mindar-ui-overlay .stat-window .animation-container {
  z-index: 6;
  top: 5vh;
}

.hand-scan-container {
  top: 5vh;
  transform: scale(0.9);
  /* translate: 0px 10px; */
}

.mindar-ui-overlay .stat-window .animation-container-scaled-up {
  transform: scale(1.3);
}

.mindar-ui-overlay .stat-window .progress-container {
  z-index: 6;
  bottom: 10px;
}

.mindar-ui-overlay .stat-window .play-img {
  opacity: 1;
  margin-top: 1vh;
  height: 18vh;
  cursor: pointer;
}

.mindar-ui-overlay .stat-window .progresstext {
  font-size: 3vh;
}

.mindar-ui-overlay .stat-window .progressbar {
  width: 300px;
  height: 4vh;
  margin-top: -23px;
  margin-left: auto;
  margin-right: auto;
}

.skanteq-logo {
  margin-top: 1vh;
  height: 8vh;
}

/* Center any absolute content */
.hand-scan-container .for-best-results,
.hand-scan-container .scan-hand,
.hand-scan-container .scan-video,
.hand-scan-container .marker,
.hand-scan-container .hold-phone,
.hand-scan-container,
.mindar-ui-overlay .stat-window .progress-container,
.mindar-ui-overlay .stat-window .animation-container
{
  position: absolute;
  left: 0; right: 0; margin-inline: auto;
}

/* Mask For Video */

.mask  {
  /* -webkit-mask-image:url(/public/media/splash/video_mask.svg);
  -webkit-mask-position:left top;
  -webkit-mask-repeat:no-repeat;
  -webkit-mask-size:100%; */

  mask-image: url(/public/media/splash/video_mask.svg);
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 240px 135px;
}

/* Older Code */

.mindar-ui-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 500;
}

.mindar-ui-overlay.hidden {
  display: none;
}

.mindar-ui-overlay .stat-window {
  position: relative;
  margin: auto;
  width: 450px;
  height: 100%;
  background: #0B0E1B;
  text-align: center;
  font-size: 25px;
  color: white;
}

.mindar-ui-overlay .stat-window div {
  font-family: Geist;
  color: #FFF;
  font-style: normal;
  line-height: normal;
}

.mindar-ui-loading .loader {
  border: 4px solid #222;
  position: absolute;
  border-top: 4px solid white;
  opacity: .8;
  border-radius: 50%;
  left: 95px;
  top: -9px;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  -webkit-animation: spin 2s linear infinite;
}

.scanning {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scanning .frame {
  width: 70%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  max-width: 400px;
}

.scanning .hand {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  max-width: 200px;
  z-index: 1000;
}

@keyframes spin {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@keyframes cardAnimation {
  0% {
    transform: translateX(-300%) translateY(-120%) scale(0.6);
    -webkit-transform: translateX(-300%) translateY(-120%) scale(0.6);
  }
  80% {
    transform: translateX(6%) translateY(1%) scale(0.15);
    -webkit-transform: translateX(6%) translateY(1%) scale(0.15);
  }
  100% {
    transform: translateX(6%) translateY(1%) scale(0.15);
    -webkit-transform: translateX(6%) translateY(1%) scale(0.15);
  }
}

@-webkit-keyframes checkAnimation {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes checkAnimation {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.checkImg {
  position: absolute;
  bottom: 0;
  max-width: 50px;
  animation: checkAnimation 6s infinite;
  -webkit-animation: checkAnimation 6s infinite;
  transform: translateX(80%) translateY(-550%);
  -webkit-transform: translateX(80%) translateY(-550%);
  z-index: 2000;
}

.markerImg {
  position: absolute;
  animation: cardAnimation 6s infinite;
  -webkit-animation: cardAnimation 6s infinite;
  max-height: 270px;
  bottom: 0;
  will-change: transform;
}



/* Other Classes (seems unrelated to status.tsx) */


.cls-1 {
  fill: url(#linear-gradient);
  -webkit-fill: url(#linear-gradient);
}

.cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16 {
  stroke-width: 0px;
}

.cls-2 {
  fill: url(#linear-gradient-11);
  -webkit-fill: url(#linear-gradient-11);
}

.cls-3 {
  fill: url(#linear-gradient-12);
  -webkit-fill: url(#linear-gradient-12);
}

.cls-4 {
  fill: url(#linear-gradient-13);
  -webkit-fill: url(#linear-gradient-13);
}

.cls-5 {
  fill: url(#linear-gradient-10);
  -webkit-fill: url(#linear-gradient-10);
}

.cls-6 {
  fill: url(#Blue);
}

.cls-7 {
  fill: #a7a9ac;
}

.cls-8 {
  fill: #d1d3d4;
}

.cls-9 {
  fill: url(#linear-gradient-4);
  -webkit-fill: url(#linear-gradient-4);
}

.cls-10 {
  fill: url(#linear-gradient-2);
  -webkit-fill: url(#linear-gradient-2);
}

.cls-11 {
  fill: url(#linear-gradient-3);
  -webkit-fill: url(#linear-gradient-3);
}

.cls-12 {
  fill: url(#linear-gradient-8);
  -webkit-fill: url(#linear-gradient-8);
}

.cls-13 {
  fill: url(#linear-gradient-9);
  -webkit-fill: url(#linear-gradient-9);
}

.cls-14 {
  fill: url(#linear-gradient-7);
  -webkit-fill: url(#linear-gradient-7);
}

.cls-15 {
  fill: url(#linear-gradient-5);
  -webkit-fill: url(#linear-gradient-5);
}

.cls-16 {
  fill: url(#linear-gradient-6);
  -webkit-fill: url(#linear-gradient-6);
}

.mindar-ui-overlay .stat-window .inner .logo {
  width: 102px;
}

.st0 {
  opacity: '0.18';
  fill: '#989898';
}

.st1 {
  fill: #FFFFFF;
}

.st2 {
  fill: #231F20;
}

.st3 {
  fill: #8DD6F7;
}

.st4 {
  fill: #4D4D4D;
}