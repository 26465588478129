/* toggle button */

.video-check-box {
    transform: scale(0.5);
}

.video-check-box input[type="checkbox"] {
    position: relative;
    appearance: none;
    width: 100px;
    height: 50px;
    background: #ccc;
    border-radius: 50px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 0.4s;
}

.video-check-box input:checked[type="checkbox"] {
    background: #04f017;
    border: 1px solid #E0E0E0;
}

.video-check-box input[type="checkbox"]::after {
    position: absolute;
    content: "";
    width: 50px;
    height: 50px;
    top: 0;
    left: 0;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transform: scale(.9);
    transition: 0.4s;
}

.video-check-box input:checked[type="checkbox"]::after {
    left: 50%;
}