

/* Animation */

/* NOTE: remember to add webkit animations too!! */

@keyframes animAllRectangles {
  0%   { opacity: 0; }
  44%  { opacity: 0; }
  45%  { opacity: 1; }
  95%  { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes animTopLeft {
  0%   { transform: translate(80px, 100px) scaleY(0%); }
  46%  { transform: translate(80px, 100px) scaleY(0%); }
  50%  { transform: translate(80px, 100px) scaleY(-100%); }
  100% { transform: translate(80px, 100px) scaleY(-100%); }
}
@keyframes animTopCenter {
  0%   { transform: translate(80px, 30px) scaleX(0%); }
  50%  { transform: translate(80px, 30px) scaleX(0%); }
  52%  { transform: translate(80px, 30px) scaleX(100%); }
  100% { transform: translate(80px, 30px) scaleX(100%); }
}
@keyframes animTopRight {
  0%   { transform: translate(210px, 30px) scaleY(0%); }
  52%  { transform: translate(210px, 30px) scaleY(0%); }
  55%  { transform: translate(210px, 30px) scaleY(100%); }
  100% { transform: translate(210px, 30px) scaleY(100%); }
}

@keyframes animBottomRight {
  0%   { transform: translate(210px, 190px) scaleY(0%); }
  46%  { transform: translate(210px, 190px) scaleY(0%); }
  50%  { transform: translate(210px, 190px) scaleY(100%); }
  100% { transform: translate(210px, 190px) scaleY(100%); }
}
@keyframes animBottomCenter {
  0%   { transform: translate(220px, 260px) scaleX(0%); }
  50%  { transform: translate(220px, 260px) scaleX(0%); }
  52%  { transform: translate(220px, 260px) scaleX(-100%); }
  100% { transform: translate(220px, 260px) scaleX(-100%); }
}
@keyframes animBottomLeft {
  0%   { transform: translate(80px, 260px) scaleY(0%); }
  52%  { transform: translate(80px, 260px) scaleY(0%); }
  55%  { transform: translate(80px, 260px) scaleY(-100%); }
  100% { transform: translate(80px, 260px) scaleY(-100%); }
}

.hold-phone-svg .top-left-anim {
  animation:         animAllRectangles 14s infinite, animTopLeft 14s infinite ease-in;
  -webkit-animation: animAllRectangles 14s infinite, animTopLeft 14s infinite;
}
.hold-phone-svg .top-center-anim {
  animation:         animAllRectangles 14s infinite, animTopCenter 14s infinite;
  -webkit-animation: animAllRectangles 14s infinite, animTopCenter 14s infinite;
}
.hold-phone-svg .top-right-anim {
  animation:         animAllRectangles 14s infinite, animTopRight 14s infinite ease-out;
  -webkit-animation: animAllRectangles 14s infinite, animTopRight 14s infinite;
}

.hold-phone-svg .bottom-right-anim {
  animation:         animAllRectangles 14s infinite, animBottomRight 14s infinite ease-in;
  -webkit-animation: animAllRectangles 14s infinite, animBottomRight 14s infinite;
}
.hold-phone-svg .bottom-center-anim {
  animation:         animAllRectangles 14s infinite, animBottomCenter 14s infinite;
  -webkit-animation: animAllRectangles 14s infinite, animBottomCenter 14s infinite;
}
.hold-phone-svg .bottom-left-anim {
  animation:         animAllRectangles 14s infinite, animBottomLeft 14s infinite ease-out;
  -webkit-animation: animAllRectangles 14s infinite, animBottomLeft 14s infinite;
}

@keyframes animHold {
  0%   { opacity: 0; transform: translateY(-10%); -webkit-transform: translateY(-10%); }
  55%  { opacity: 0; transform: translateY(-10%); -webkit-transform: translateY(-10%); }
  56%  { opacity: 1; transform: translateY(0%); -webkit-transform: translateY(0%); }
  95%  { opacity: 1; transform: translateY(0%); -webkit-transform: translateY(0%); }
  100% { opacity: 0; transform: translateY(0%); -webkit-transform: translateY(0%); }
}
@keyframes animPhone {
  0%   { opacity: 0; transform: translateY(-10%); -webkit-transform: translateY(-10%); }
  56%  { opacity: 0; transform: translateY(-10%); -webkit-transform: translateY(-10%); }
  57%  { opacity: 1; transform: translateY(0%); -webkit-transform: translateY(0%); }
  95%  { opacity: 1; transform: translateY(0%); -webkit-transform: translateY(0%); }
  100% { opacity: 0; transform: translateY(0%); -webkit-transform: translateY(0%); }
}
@keyframes animOverImage {
  0%   { opacity: 0; }
  58%  { opacity: 0; }
  60%  { opacity: 1; }
  95%  { opacity: 1; }
  100% { opacity: 0; }
}

/* Regular CSS */

.hold-phone-svg {
  z-index: 10;
  top: 15px;
  height: 300px;
}

.hold-phone-labels {
  height: 300px;
}

.hold-phone-labels .hold {
  top: 100px;
  font-size: 44px;
  font-family: 'Kanit Bold' !important;
}
.hold-phone-labels .phone {
  top: 140px;
  font-size: 44px;
  font-family: 'Kanit Bold' !important;
}
.hold-phone-labels .over-image {
  top: 200px;
  font-size: 18px;
}

.hold-phone-labels .hold-anim {
  animation:         animAllRectangles 14s infinite, animHold 14s infinite;
  -webkit-animation: animAllRectangles 14s infinite, animHold 14s infinite;
}
.hold-phone-labels .phone-anim {
  animation:         animAllRectangles 14s infinite, animPhone 14s infinite;
  -webkit-animation: animAllRectangles 14s infinite, animPhone 14s infinite;
}
.hold-phone-labels .over-image-anim {
  animation:         animAllRectangles 14s infinite, animOverImage 14s infinite;
  -webkit-animation: animAllRectangles 14s infinite, animOverImage 14s infinite;
}

/* Shared attributes for align-centered abosolute positioning */

.hold-phone-labels .hold,
.hold-phone-labels .phone,
.hold-phone-labels .over-image,
.hold-phone-svg {
  position: absolute;
  left: 0; right: 0; margin-inline: auto;
}

/* Font Family */

@font-face {
  font-family: "Kanit Bold";
  src: url('../../_4threal/helpers/fonts/Kanit/Kanit-Bold.ttf') format("truetype");
  font-weight: bold;
}
