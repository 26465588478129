.over-main {
  font-size: 50px;
  color: black;
  font-weight: 700;
  line-height: normal;
}

.overview-title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 10px;
}

.main-overview {
  border-radius: 7px;
  background: #fff;
  box-shadow: 1px 3px 10px 3px rgba(173, 173, 173, 0.13);
  padding: 10px 0px 15px 25px;
  margin-bottom: 10px;
}

.main-map {
  border-radius: 7px;
  background: #fff;
  box-shadow: 1px 3px 10px 3px rgba(173, 173, 173, 0.13);
}

.main-scancard {
  border-radius: 7px;
  background: #fff;
  box-shadow: 1px 3px 10px 3px rgba(173, 173, 173, 0.13);
  padding: 15px 20px;
  height: 360px;
}

.update-title {
  color: #767676;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.analytic-dropdown {
  color: #767676;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media only screen and (max-width: 1130px) {
  .over-main {
    font-size: 30px;
    color: black;
    font-weight: 700;
  }
}

@media only screen and (max-width: 720px) {
  .over-main {
    font-size: 20px;
    color: black;
    font-weight: 700;
  }
}

@media only screen and (max-width: 478px) {
  .over-main {
    font-size: 20px;
    color: black;
    font-weight: 700;
  }

  .main-scancard {
    margin-bottom: 10px !important;
  }
}
