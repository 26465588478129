.pill-btn{
    display: inline-block;
    box-sizing: border-box;
    height: 25px;
    line-height: 20px;
    background: #6188FF;
    padding: 0px 15px;
    border-radius: 999em;
    text-decoration: none;
    color: #fff;
    font-size: 10px;
        vertical-align: bottom;
      white-space: nowrap;
    border: 3px solid #5C81F2;
    letter-spacing: 2px;
    transition: .15s background-color,.15s border-color,.15s color,.15s fill;
    font-weight: 200;
  }